import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReplyBoxComponent } from 'src/app/modules/shared/components/reply-box/reply-box.component';
import { MBRRequestsService } from 'src/app/services/mbr-requests.service';

@Component({
  selector: 'app-mbr-request-detail',
  templateUrl: './mbr-request-detail.component.html',
  styleUrls: ['./mbr-request-detail.component.scss']
})
export class MbrRequestDetailComponent  implements OnInit {

  requestId: any = "";
  requestData: any;
  requestComments: any[];
  isLoading: boolean = false;

  constructor(private mbrService: MBRRequestsService, private route: ActivatedRoute) {
  }

  @ViewChild(ReplyBoxComponent) replyComponent: ReplyBoxComponent;


  ngOnInit(): void {

    this.requestId = this.route.snapshot.paramMap.get('id');
      this.mbrService.getById(this.requestId).subscribe(value => {
        this.requestData = value.data;
      });

      this.mbrService.getComments(this.requestId).subscribe(value => {
        this.requestComments = value.data;
      });
  }

  // For ng select 
  statusList = [
    {
      id: 0,
      name: 'Select Action',
      icon: 'fa-arrow-down',
      color: 'primary'
    },
    {
      id: 1,
      name: 'Reply',
      icon: 'fa-arrow-left',
      color: 'primary'
    },
    {
      id: 3,
      name: 'Reject',
      icon: 'fa-times-octagon',
      color: 'danger'
    },
    {
      id: 4,
      name: 'Approve',
      icon: 'fa-badge-check',
      color: 'success'
    },

  ];
  selectedStatus = this.statusList[0].name;

  uploadingEventHandler(status: boolean): void {
    this.isLoading = status;
  }

  onReply(data: any) {

    if(data.result) {
      this.ngOnInit();
    } 
    this.selectedStatus = 'Select Action';
    
  }
}