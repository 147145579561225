<div class="pcs-container">
  <div class="pcs-header">
    <h3 class="pcs-title">MBR Request</h3>
    <div class="card-actions d-flex">
      <div class="submit-status">
        <span
          class="status-value"
          [ngClass]="this.requestData?.mbrRequestStatus?.name.replace(' ', '_')"
          >{{ this.requestData?.mbrRequestStatus?.name ?? "" }}</span
        >
      </div>
      <ng-select
        *ngIf="requestData && requestData.canTakeAction"
        class="ng-select-style"
        [items]="statusList"
        [(ngModel)]="selectedStatus"
        bindLabel="name"
        bindValue="name"
        [clearable]="false"
      >
        <ng-template ng-label-tmp let-item="item">
          <div class="select-label {{ item.color }}">
            <i class="fal {{ item.icon }}"></i>
            {{ item.name }}
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>
  <br>
  <div class="card-body">
    <div class="submission-data-card" *ngIf="requestData">
      <table class="table table-bordered">
        <tbody>
          <tr>
            <td>
              <span class="data-label"
                ><span class="data-label">Request No:</span></span
              >
            </td>
            <td>
              <span class="data-value">{{ requestData.formNo }}</span>
            </td>
            <td><span class="data-label">Review Deadline:</span></td>
            <td>
              <span class="data-value">{{
                requestData.dueDate | date : "MM-dd-yyyy"
              }}</span>
            </td>
          </tr>
          <tr>
            <td><span class="data-label">Product:</span></td>
            <td colspan="3">
              <span class="data-value">{{ requestData.product.name }}</span>
            </td>
          </tr>
          <tr *ngIf="requestData.notes">
            <td>Notes:</td>
            <td colspan="3">
              <span class="data-value">{{ requestData.notes }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row" *ngIf="requestData && requestData.documents.length">
      <app-attachments-group [attachments]="requestData.documents" />
    </div>
    <!---------------------------------------------------------- SEND BACK FORM -------------------------------------------------------->
    <app-reply-box
      [hide]="selectedStatus === 'Reply'"
      (actionResult)="onReply($event)"
      [entityId]="requestId"
      [type]="'mbr-request'"
    />

    <!---------------------------------------------------------- REJECT FORM -------------------------------------------------------->
    <app-reject-box
      [hide]="selectedStatus === 'Reject'"
      (actionResult)="onReply($event)"
      [entityId]="requestId"
      [type]="'mbr-request'"
    />

    <!---------------------------------------------------------- Approve FORM -------------------------------------------------------->
    <app-consolidate-box
      [hide]="selectedStatus === 'Approve'"
      (actionResult)="onReply($event)"
      [entityId]="requestId"
      [type]="'mbr-request'"
      [canUpload]="false"
      [approveButtonText]="'Approve'"
    />

    <div class="row my-3">
      <div *ngFor="let comment of requestComments">
        <app-comment [comment]="comment"></app-comment>
      </div>
    </div>
  </div>
</div>
