import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule } from '@angular/forms';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
// For file upload
import { NgxDropzoneModule } from 'ngx-dropzone';
import { LenderModule } from './modules/lender/lender.module';
import { ProviderModule } from './modules/provider/provider.module';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AuthHttpInterceptor, AuthModule, provideAuth0 } from '@auth0/auth0-angular';
import { ErrorInterceptor } from './services/error.interceptor';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
@NgModule({ declarations: [
        AppComponent,
        LoginComponent,
        NotFoundComponent,
        AccessDeniedComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        NgxDropzoneModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule, // required animations module
        LenderModule,
        ProviderModule,
        MatSnackBarModule,
        AuthModule.forRoot({
            domain: environment.auth0.domain,
            clientId: environment.auth0.clientId,
            authorizationParams: {
                redirect_uri: `${window.location.origin}/login`,
                audience: environment.auth0.audience,
            },
            cacheLocation: 'memory',
            httpInterceptor: {
                allowedList: [{
                        uri: `${environment.baseUrl}/*`,
                        tokenOptions: {
                            authorizationParams: {
                                audience: environment.auth0.audience,
                                scope: 'read:current_user'
                            }
                        }
                    }]
            }
        })], providers: [
        ...(environment.production
            ? []
            : []),
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
