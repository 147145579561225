import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusBadgeComponent } from '../../status-badge/status-badge.component';
import { ICardListInfo, ICardListStatus } from './card-list-item.interface';
import { Router, RouterModule } from '@angular/router';
import { InitialsPipe } from '../../../pipes/initials.pipe';

@Component({
  selector: 'app-card-list-item',
  standalone: true,
  imports: [CommonModule, RouterModule, StatusBadgeComponent, InitialsPipe],
  templateUrl: './card-list-item.component.html',
  styleUrls: ['./card-list-item.component.scss']
})
export class CardListItemComponent {
  @Input() infoContent: ICardListInfo[];
  @Input() statusContent: ICardListStatus[];
  @Input() detailRoute: string;
  @Input() flex: 'half' | '3-2' | '4-1';
  @Input() badgeSize: 'small' | 'large';

  constructor(private router: Router) {}

  goDetail() {
    this.router.navigate([this.detailRoute])
  }

  getExtraCount(): string {
    return `+${this.statusContent.length - 3}`;
  }

  isArray(value:any) {
    return Array.isArray(value);
  }

  getAcronym(text: string){
    //! HARDCODED REMOVE LATER
    if(text == "Excess Wear & Use Waiver") {
      return 'E W U';
    }
    else if(text == 'Prepaid Maintenance Plan') {
      return 'P P M';
    }
    else if(text == 'Tire & Wheel with Key Protection') {
      return 'T W K';
    }
    else if(text == 'Key Protection') {
      return "K e y"
    }
    else
      return text;
  }
}
