import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LoaderComponent } from '../loader/loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-right-sidebar-container',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, MatProgressSpinnerModule],
  templateUrl: './right-sidebar-container.component.html',
  styleUrls: ['./right-sidebar-container.component.scss']
})
export class RightSidebarContainerComponent {
  @Input() title: string;
  @Input() footerSubmitCustomLabel?: string;
  @Input() disableSubmit: boolean;
  @Input() loading: boolean = false;
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() submit: EventEmitter<any> = new EventEmitter();

  cancelClicked() {
    this.cancel.emit()
  }

  submitClicked() {
    this.submit.emit()
  }
}
