import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { APIResponseModel } from '../models/api-response-model';

@Injectable({
  providedIn: 'root'
})

export class FormConsolidationsService extends BaseService {

  constructor(http: HttpClient) {
    super(http, `formconsolidations`);
  }

  uploadDocument(id: string, file: File): Observable<APIResponseModel<any>> {

    let formData = new FormData();
    formData.append(file.name, file);

    return this.http.post<APIResponseModel<any>>(`${this.baseUrl}/documentupload/${id}`, formData)
    .pipe(
        catchError((err: HttpErrorResponse) => {
           return this.httpErrorHandler(err, {});
        })
    );
  }

  getComments(id: any): Observable<APIResponseModel<any[]>> {
    return this.http.get<APIResponseModel<any[]>>(`${this.baseUrl}/comments/${id}`)
    .pipe(
        catchError((err: HttpErrorResponse) => {
           return this.httpErrorHandler(err, []);
        })
    );
  }

  updateStatus(id: string, action: string, data: any): Observable<APIResponseModel<any>> {

    return this.http.post<APIResponseModel<any>>(`${this.baseUrl}/${id}/${action}`, data)
    .pipe(
        catchError((err: HttpErrorResponse) => {
           return this.httpErrorHandler(err, {});
        })
    );
  }

}
