import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadDocumentsComponent } from './components/upload-documents/upload-documents.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { SelectDropdownComponent } from './components/select-dropdown/select-dropdown.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgSelectModule } from '@ng-select/ng-select';
import { MultiSelectDropdownComponent } from './components/multi-select-dropdown/multi-select-dropdown.component';
import SubmissionCetailCardComponent from './components/submission-detail-card/submission-detail-card.component';
import { CommentComponent } from './components/comment/comment.component';
import { AttachmentsGroupComponent } from './components/attachments-group/attachments-group.component';
import { ReplyBoxComponent } from './components/reply-box/reply-box.component';
import { ApproveBoxComponent } from './components/approve-box/approve-box.component';
import { RejectBoxComponent } from './components/reject-box/reject-box.component';
import { ConsolidateBoxComponent } from './components/consolidate-box/consolidate-box.component';
import { GridDocumentComponent } from './components/grid-document/grid-document.component';
import { GridBadgeColumnComponent } from './components/grid-badge-column/grid-badge-column.component';
import { PopupConfirmationComponent } from './components/popup-confirmation/popup-confirmation.component';
import { LeftSidebarComponent } from './components/left-sidebar/left-sidebar.component';
import { MainNavigationComponent } from './components/main-navigation/main-navigation.component';
import { RouterModule } from '@angular/router';
import { RightSidebarComponent } from './components/right-sidebar/right-sidebar.component';
import { OrganizationCardComponent } from './components/organization-card/organization-card.component';
import { FormStatusComponent } from 'src/app/components/form-status/form-status.component';
import { FormStatusCellRenderer } from 'src/app/components/form-status/form-status-cell-renderer.component';
import { FormStatusListComponent } from 'src/app/components/form-status-list/form-status-list.component';
import { TooltipModule } from 'src/app/components/tooltip/tooltip.module';
import { NotificationMenuTitleComponent } from './components/main-navigation/notification-menu-title/notification-menu-title';
import { NotificationMenuItemComponent } from './components/main-navigation/notification-menu-item/notification-menu-item';
import { InitialsPipe } from './pipes/initials.pipe';
import { AvatarProfileComponent } from './components/avatar-profile/avatar-profile.component';
import { ImageRendererComponent } from './components/image-renderer/image-renderer.component';
import { NavItemComponent } from './components/left-sidebar/nav-item/nav-item.component';
import { BypassHtmlSanitizerPipe } from './pipes/bypassHtmlSanitizer.pipe';
import { WelcomeMessageComponent } from './components/welcome-message/welcome-message.component';
import { CardStatsComponent } from './components/cards/card-stats/card-stats.component';
import { CardListItemComponent } from './components/cards/card-list-item/card-list-item.component';
import { StatusBadgeComponent } from './components/status-badge/status-badge.component';
import { DashboardCardComponent } from './components/pcs-dashboard/dashboard-card/dashboard-card.component';
import { PcsDashboardComponent } from './components/pcs-dashboard/pcs-dashboard.component';
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { MatIconModule } from '@angular/material/icon';
import { MatMultiSelectComponent } from './components/mat-multiselect/mat-multiselect.component'
import { MatSelectAllComponent } from './components/mat-multiselect/mat-select-all/mat-select-all.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { LoaderComponent } from './components/loader/loader.component';
import { MatButtonModule } from '@angular/material/button';
import { AgGridWrapperComponent } from './components/ag-grid-wrapper/ag-grid-wrapper.component';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ProviderDocumentsComponent } from './components/provider-documents/provider-documents.component';
import { ProviderDocumentCardComponent } from './components/provider-documents/provider-documents-card/provider-document-card.component';


import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatSidenavModule } from '@angular/material/sidenav';
import {MatRadioModule} from '@angular/material/radio';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatDialogModule} from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from './components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { InformationMessageComponent } from './components/information-message/information-message.component';
import { RightSidebarContainerComponent } from './components/right-sidebar-container/right-sidebar-container.component';
import { GeneralDialogComponent } from './components/general-dialog/general-dialog.component';
import { ItemTextCellRenderer } from './components/icon-text-cell-renderer/icon-text-cell-renderer.component';
import { UserDetailComponent } from './components/user-detail/user-detail.component';
import { ToastMessageComponent } from './components/toast-message/toast-message.component';
import { ProfileWidgetComponent } from './components/profile-widget/profile-widget.component';
import { PortalModule } from '@angular/cdk/portal';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatBadgeModule} from '@angular/material/badge'
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { GeneralSearchFormComponent } from './components/main-navigation/general-search/general-search-form.component';
import {MatMenuModule} from '@angular/material/menu';

@NgModule({
  declarations: [
    UploadDocumentsComponent,
    SelectDropdownComponent,
    MultiSelectDropdownComponent,
    SubmissionCetailCardComponent,
    CommentComponent,
    ReplyBoxComponent,
    AttachmentsGroupComponent,
    RejectBoxComponent,
    ApproveBoxComponent,
    ConsolidateBoxComponent,
    GridDocumentComponent,
    GridBadgeColumnComponent,
    PopupConfirmationComponent,
    MainNavigationComponent,
    RightSidebarComponent,
    OrganizationCardComponent,
    FormStatusComponent,
    FormStatusListComponent,
    FormStatusCellRenderer,
    ProviderDocumentsComponent,
    ProviderDocumentCardComponent,
    DeleteConfirmationDialogComponent,
    InformationMessageComponent,
    GeneralDialogComponent,
    UserDetailComponent,
    ProfileWidgetComponent,
    GeneralSearchFormComponent
  ],
  imports: [
    CommonModule,
    NgxDropzoneModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    NgMultiSelectDropDownModule,
    RouterModule,
    TooltipModule,
    //standalone components
    NotificationMenuTitleComponent,
    NotificationMenuItemComponent,
    InitialsPipe,
    AvatarProfileComponent,
    ImageRendererComponent,
    NavItemComponent,
    BypassHtmlSanitizerPipe,
    WelcomeMessageComponent,
    CardStatsComponent,
    CardListItemComponent,
    StatusBadgeComponent,
    DashboardCardComponent,
    LeftSidebarComponent,
    PcsDashboardComponent,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMultiSelectComponent,
    MatSelectAllComponent,
    MatBadgeModule,
    LoaderComponent,
    AgGridWrapperComponent,
    MatTabsModule,
    MatPaginatorModule,
    MatCardModule,
    MatChipsModule,
    MatSidenavModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatDialogModule,
    RightSidebarContainerComponent,
    ItemTextCellRenderer,
    PortalModule,
    ToastMessageComponent,
    MatMenuModule
  ],
  exports: [
    UploadDocumentsComponent,
    SelectDropdownComponent,
    MultiSelectDropdownComponent,
    SubmissionCetailCardComponent,
    CommentComponent,
    ReplyBoxComponent,
    AttachmentsGroupComponent,
    RejectBoxComponent,
    ApproveBoxComponent,
    ConsolidateBoxComponent,
    GridDocumentComponent,
    GridBadgeColumnComponent,
    PopupConfirmationComponent,
    MainNavigationComponent,
    RightSidebarComponent,
    OrganizationCardComponent,
    FormStatusComponent,
    FormStatusListComponent,
    FormStatusCellRenderer,
    InitialsPipe,
    BypassHtmlSanitizerPipe,
    WelcomeMessageComponent,
    CardStatsComponent,
    CardListItemComponent,
    StatusBadgeComponent,
    DashboardCardComponent,
    LeftSidebarComponent,
    PcsDashboardComponent,
    ProviderDocumentsComponent,
    ProviderDocumentCardComponent,
    InformationMessageComponent,

    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatTabsModule,
    MatPaginatorModule,
    MatCardModule,
    MatChipsModule,
    MatSidenavModule,
    MatRadioModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatBadgeModule,
    MatMultiSelectComponent,
    MatSelectAllComponent,
    LoaderComponent,
    AgGridWrapperComponent,
    RightSidebarContainerComponent,
    ItemTextCellRenderer,
    PortalModule,
    ToastMessageComponent,
    GeneralSearchFormComponent,
    MatMenuModule
  ],
  providers: [MatPaginatorIntl]
})
export class SharedModule { }
