<div class="col-lg-8 col-md-10">
    <div class="submit-box submited-sendback primary">
        <div class="submit-user">
            <div class="user-icon" [ngStyle]="{'background-color': (comment.userDetails != undefined) ? (comment.userDetails.type == 1 || comment.userDetails.type == 2) ? '#1C449C' : (comment.userDetails.type == 4) ? '#132C6C' : '#24ACE3':'#24ACE3' }">
                <i class="fal fa-user-circle"></i>
            </div>
            <div>
                <h6>
                    <span class="user-name">{{comment.user.firstName}} {{comment.user.lastName}}</span>
                    <span class="badge" [ngStyle]="{'background-color':  (comment.userDetails != undefined) ? (comment.userDetails.type == 1 || comment.userDetails.type == 2) ? '#1C449C' : (comment.userDetails.type == 4) ? '#132C6C' : '#24ACE3':'#24ACE3' }">{{comment.organization}}</span>
                </h6>
                <p class="submit-date">{{ comment.dateTime + 'Z' | date: 'MM-dd-yyyy HH:mm'}}</p>
            </div>
        </div>
        <div class="subitted-details">
            <p>{{comment.comment}}</p>
            <div *ngFor="let doc of comment.documents" class="submitted-attachment" (click)="downloadFile(doc)">
                <i class="far" [ngClass]="getDocType(doc) === 'pdf' ? 'fa-file-pdf': 'fa-file-word'"></i>
                <span>{{doc.name}}</span>
            </div>
        </div>
    </div>
</div>
