export const environment: {
  baseUrl?: string;
  production: boolean;
  demo: boolean;
  auth0: {
    domain: string,
    clientId: string,
    audience: string
  };
} = {
  baseUrl: 'https://api.stg.portal.proteriscompliance.com',
  production: false,
  demo: true,
  auth0: {
    domain: 'stg.auth.portal.proteriscompliance.com',
    clientId: 'epHkisq6Tj5isqG6v7Afmszv0uyRD4Tv',
    audience: 'https://stg-new-proteris-api/'
  }
};
